import useAuthStore from "@/stores/authStore";

export function get_files(directory) {
    const authStore = useAuthStore()  // TODO: move out to outer scope for performance reasons

    return authStore.api_post(
        "get_files",
        {directory: directory},
    )
}

export function get_structure() {
    const authStore = useAuthStore()  // TODO: move out to outer scope for performance reasons

    return authStore.api_post(
        "get_structure",
    )
}