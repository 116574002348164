import {get_structure} from "@/scripts/get_files";
import {DirectoryIcon} from "@/njdesktop_custom/extensions/Directory";
import WindowEventsDemo from "@/njdesktop_custom/demo/windowEventsDemo";
import Documentation from "@/njdesktop_custom/demo/documentation";
import useAuthStore from "@/stores/authStore";
import handle_error from "@/scripts/handle_error";
import useLoadingStore from "@/stores/loadingStore";

export default async function update_desktop(desktop) {
    let response = undefined
    const loadingStore = useLoadingStore();
    try {
        loadingStore.push()
        response = await get_structure();
        loadingStore.pop()
    } catch (error) {
        handle_error(error)
        loadingStore.pop()
        return
    }

    let structure = response.data["structure"]

    const user_root_index = structure.findIndex(e=>e.name === "__user_root")

    if (user_root_index !== -1) {
        structure = structure.filter(e=>e.name !== "__user_root").concat(structure.filter(e=>e.name === "__user_root")[0].elems)
    }

    desktop.getIconList().clear()
    for(const elem of structure) {
        const icon = {
            "file": desktop.icon_file,
            "directory": desktop.icon_directory
        }[elem["type"]]
        new DirectoryIcon(desktop, elem["name"], elem["elems"], icon, elem["type"]==="file")
    }

    // new WindowEventsDemo(desktop);
    // new Documentation(desktop);
}