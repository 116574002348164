
<template>
  <video autoplay muted loop id="background-video">
    <source src="@/assets/landscape_1.mp4" type="video/mp4"/>
  </video>
  <!-- Optional: some overlay text to describe the video -->
  <div class="content">
    <h1 class="has-text-white">Hallo.</h1>
    <!-- <h1 class="has-text-white">Hallo {{ username }} {{ authStore.dummy_username }}.</h1> -->
    <!-- <button @click="authStore.dummy_username = 'Yes'">Change state</button> -->
    <p class="has-text-white">Bitte lassen Sie uns Ihre Verantwortung übernehmen</p>
    <DefaultFooter/>
  </div>
</template>

<style scoped lang="scss">
/* Style the video: 100% width and height to cover the entire window */
#background-video {
  position: fixed;
  // right: 0;
  // bottom: 0;
  min-width: 100%;
  min-height: 100%;
  vertical-align: middle;
  // display: table-cell;
  object-fit: cover;
}

/* Add some content at the bottom of the video/page */
.content {
  position: fixed;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.15);
  width: 100%;
  padding: 20px;
}
</style>

<script>
import { defineComponent } from 'vue';

import DefaultFooter from "@/components/DefaultFooter.vue";
import useAuthStore from "@/stores/authStore"

export default defineComponent({
  name: 'WelcomeScreen',
  components: { DefaultFooter },
  props: {
    username: {
      type: String,
      default: "Étienne",
    },
  },
  setup() {
    const authStore = useAuthStore();
    return {
      authStore
    }
  }
});
</script>
