import useAuthStore from "@/stores/authStore";

function log_return_value(return_value, item, path) {
    if (return_value !== undefined) {
        console.log("In path: ");
        console.log(path)
        console.log("Item: ")
        console.log(item)
        console.log("Return value: ")
        console.log(return_value)
        console.log()
    }
}

export default async function traverse_file_tree(
    file_callback,       // (file_entry, path) => void
    directory_callback,  // (item      , path) => void
    item,
    path = [],
    ) {
    if (item.isFile) {
        const file_entry = item;
        // noinspection JSValidateTypes
        const ret = await file_callback(file_entry, path);
        log_return_value(ret, file_entry, path);
    }
    else if (item.isDirectory) {
        // noinspection JSValidateTypes
        const ret = directory_callback(item, path)
        log_return_value(ret, item, path)

        // Get folder contents

        await new Promise((resolve, reject) => {
            const dirReader = item.createReader();
            dirReader.readEntries(async function(entries) {
                for (const entry of entries) {
                    await traverse_file_tree(file_callback, directory_callback, entry, path.concat(item.name));
                }
                resolve()
            });
        });
    }
}