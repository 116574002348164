import { defineStore } from 'pinia'
import auth0 from 'auth0-js'
import router from "@/router";
import axios from "axios";
import { useSessionStorage } from "@vueuse/core"

const useSimpleJWTStore = defineStore("simpleJWTStore", {
    state: () => ({
        authResult: useSessionStorage("authResult", null, {
            // need to be explicitly declared. null is not an object and will imply a non-json serializer
            serializer: {
                    read: (v) => v ? JSON.parse(v) : null,
                    write: (v) => JSON.stringify(v),
                },
            },
        ),
    }),
    getters: {
        accessToken: (state) => { if (state.authResult) return state.authResult.access },
        valid: (state) => {
            const accessToken = state.authResult.accessToken
            // if not unauthorized return True
        },
    },
    actions: {
        isAuthenticated() {
            console.log("Access token:")
            console.log(this.accessToken)

            return !!this.authResult
        },
        async login(username, password) {
            const ret = await this.api_post(
                "token/",
                {
                    "username": username,
                    "password": password,
                },
                false,
            )
            this.authResult = ret.data
            router.replace('/')
            },
        handleAuthentication(){
            if (this.isAuthenticated()) {
                console.log("Authenticated")
                console.log(this.authResult)
                return;
            }
            console.log("Not authenticated")
        },
        logout() {
            this.authResult = null
            router.replace('/')
        },
        request_api(
            api_name,
            payload,
            request_function,
            authorized = true,
            configs = {}
        ){
            const is_dev = document.defaultView.__VUE_DEVTOOLS_GLOBAL_HOOK__.enabled === true
            if (is_dev) { console.log("Development mode") }
            /*if (authorized) {
                assert(
                    this.accessToken !== undefined,
                    "Trying to execute authorized action without token"
                )
            }*/
            const axiosInstance = axios.create({
                baseURL: is_dev ? 'http://localhost:8000/api' : 'https://109-236-88-43.hosted-by-worldstream.net/api',
                headers: Object.assign(configs, [{}, {Authorization: `Bearer ${this.accessToken}`}][authorized])
            });

            // console.log(this.accessToken)

            switch (request_function) {  // TODO: yes, this is very bad
                case axios.get:
                    return axiosInstance.get(`/${api_name}`, payload)
                case axios.post:
                    return axiosInstance.post(`/${api_name}`, payload)
                case axios.put:
                    return axiosInstance.put(`/${api_name}`, payload)
                case axios.delete:
                    return axiosInstance.delete(`/${api_name}`, payload)
                default:
                    throw `Unknown request request: ${request_function}`
            }
        },
        api_get(api_name, payload = {}, authorized = true, configs = {}) { return this.request_api(api_name, payload, axios.get, authorized, configs) },
        api_post(api_name, payload = {}, authorized = true, configs = {}) { return this.request_api(api_name, payload, axios.post, authorized, configs) },
        api_put(api_name, payload = {}, authorized = true, configs = {}) { return this.request_api(api_name, payload, axios.put, authorized, configs) },
        api_delete(api_name, payload = {}, authorized = true, configs = {}) { return this.request_api(api_name, payload, axios.delete, authorized, configs) },
    },
})

export default useSimpleJWTStore