import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import DemoScreen from "@/views/DemoScreen.vue";
import HomeScreen from "@/views/HomeScreen.vue";
import WelcomeScreen from "@/views/WelcomeScreen.vue";
import AuthTest from "@/views/AuthTest.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'welcome_screen',
    component: WelcomeScreen,
  },
  {
    path: '/demo',
    name: 'demo_screen',
    component: DemoScreen,
  },
  {
    path: '/home',
    name: 'home_screen',
    component: HomeScreen,
  },
  {
    path: '/welcome_lazy',
    name: 'welcome_screen_lazy_example',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "welcome_lazy" */ '@/views/WelcomeScreen.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
