import { useSessionStorage } from "@vueuse/core"
import { defineStore } from 'pinia'
import auth0 from 'auth0-js'
import router from "@/router";
import axios from "axios";

const useLoadingStore = defineStore("loadingStore", {
    state: () => ({
        loading_operations: 0,
    }),
    actions: {
        on_change() {
            if (this.loading_operations > 0) {
                document.body.style.cursor='wait'
            } else {
                document.body.style.cursor=''
            }
        },
        push() {
            this.loading_operations++
            this.on_change()
        },
        pop() {
            this.loading_operations--
            this.on_change()
        },
    },
})

export default useLoadingStore;