<template>
  <div v-if="!authStore.isAuthenticated()"><LogInButton/></div>
  <div v-else><LogOutButton/></div>
</template>
<script>
import {computed, defineComponent} from 'vue'
import LogInButton from '@/components/LogInButton.vue'
import LogOutButton from '@/components/LogOutButton.vue'
import useAuthStore from "@/stores/authStore";

export default defineComponent({
  name: "LogInOrOutButton",
  components: { LogInButton, LogOutButton },
  setup() {
    const authStore = useAuthStore()
    const short_token = computed(() => {
      if (authStore.accessToken) return authStore.accessToken.slice(0, 10)
      return "None"
    })
    return {
      authStore,
      short_token,
      message: null,
    };
  },
});
</script>

<style scoped lang="scss">

</style>