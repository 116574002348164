export default class UploadableFile {
    public file: File;
    public id: string;
    public url: string;
    public status: any;
    public metadata: any;
    constructor(file: File) {
        this.file = file
        this.id = `${file.name}-${file.size}-${file.lastModified}-${file.type}`
        this.url = URL.createObjectURL(file)
        this.status = null
        this.metadata = null
    }
}