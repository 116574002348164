<template>
  <div :data-active="active"
       @dragenter.prevent="setActive" @dragover.prevent="setActive"
       @dragleave.prevent="setInactive" @drop.prevent="onDrop">
    <slot :dropZoneActive="active"></slot>
  </div>
</template>

<script lang="ts">
import { ref, onMounted, onUnmounted, defineComponent } from "vue";
export default defineComponent({
  name: "DropZone",
})
</script>

<script setup lang="ts">
const emit = defineEmits<{
  (e: 'files-dropped', id: File[]): void,
  (e: 'remove', id: File[]): void,
}>()

// Create `active` state and manage it with functions
let active = ref(false)
let inActiveTimeout: number | null = null // add a variable to hold the timeout key

function setActive() {
  active.value = true
  if (inActiveTimeout !== null) {
    clearTimeout(inActiveTimeout) // clear the timeout
    inActiveTimeout = null
  }
}
function setInactive() {
  // wrap it in a `setTimeout`
  inActiveTimeout = setTimeout(() => {
    active.value = false
  }, 50)
}

function onDrop(e: DragEvent): void {
  setInactive() // add this line too
  if (null !== e.dataTransfer) emit("files-dropped", [...e.dataTransfer.files])
}

function preventDefaults(e: Event): void { e.preventDefault() }

const events = ['dragenter', 'dragover', 'dragleave', 'drop']

onMounted(() => {
  events.forEach((eventName) => {
    document.body.addEventListener(eventName, preventDefaults)
  })
})

onUnmounted(() => {
  events.forEach((eventName) => {
    document.body.removeEventListener(eventName, preventDefaults)
  })
})
</script>

<style scoped lang="scss">

</style>