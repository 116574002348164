import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ade3a7ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "metadata has-text-white unselectable"
}
const _hoisted_2 = ["src", "alt", "title"]
const _hoisted_3 = { class: "status-indicator loading-indicator unselectable" }
const _hoisted_4 = { class: "status-indicator success-indicator unselectable" }
const _hoisted_5 = { class: "status-indicator failure-indicator unselectable" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), { class: "file-preview" }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hovered = true)),
        onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hovered = false))
      }, [
        (!_ctx.hovered && _ctx.file.metadata != null)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.get_file_metadata), 1))
          : _createCommentVNode("", true)
      ], 32),
      _createElementVNode("img", {
        src: _ctx.file.url,
        alt: _ctx.file.file.name,
        title: _ctx.file.file.name,
        width: "200",
        height: "300",
        class: "unselectable"
      }, null, 8, _hoisted_2),
      _withDirectives(_createElementVNode("span", _hoisted_3, "In Progress", 512), [
        [_vShow, _ctx.file.status == 'loading']
      ]),
      _withDirectives(_createElementVNode("span", _hoisted_4, "Uploaded", 512), [
        [_vShow, _ctx.file.status == true]
      ]),
      _withDirectives(_createElementVNode("span", _hoisted_5, "Error", 512), [
        [_vShow, _ctx.file.status == false]
      ]),
      _createElementVNode("button", {
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('remove', _ctx.file))),
        class: "close-icon unselectable",
        "aria-label": "Remove"
      }, "×")
    ]),
    _: 1
  }))
}