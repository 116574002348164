<template>
  <div class="section has-background-black" style="height: 100%">
    <FileUploadZone/>
    <div id="desktop"></div>
    <DefaultFooter/>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DefaultFooter from "@/components/DefaultFooter.vue";
import FileUploadZone from "@/components/FileUploadZone.vue";

export default defineComponent({
  name: 'DemoScreen',
  components: { DefaultFooter, FileUploadZone },
});
</script>

<style scoped lang="scss">
</style>