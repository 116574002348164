<template>
  <button class="button is-primary" @click="authStore.login">Log in</button>
</template>

<script>
import { defineComponent } from "vue";
import useAuthStore from "@/stores/authStore";

export default defineComponent({
  name: 'LogInButton',
  components: {},
  setup() {
    const authStore = useAuthStore()
    return {
      authStore
    };
  }
});

</script>

<style scoped lang="scss">

</style>