import useAuthStore from "@/stores/authStore";

export async function uploadFile(file) {
    const authStore = useAuthStore()  // TODO: move out to outer scope for performance reasons

    // set up the request data
    const formData = new FormData()
    formData.append('files', file.file)

    // track status and upload file
    file.status = 'loading'

    const response = await authStore.api_post(
        "upload_demo",
        formData,
        {"content-type": 'multipart/form-data'}
    )

    // change status to indicate the success of the upload request
    file.status = response.ok
    file.metadata = response.data.message

    return response
}

export function uploadFiles(files) {
    return Promise.all(
        files.map((file) => uploadFile(file))
    ).then((result) => { console.log(result) })
        .catch(error => {
            console.log(error)
            if (error.response !== undefined)
                alert(error.response.data.message)
            else if (error.message !== undefined)
                alert(error.message)
        })
}

