import {Ref, ref} from 'vue'
import UploadableFile from "@/scripts/UploadableFile";

export default function () {
    const files: Ref<UploadableFile[]> = ref([])

    function addFiles(newFiles: FileList) {
        const newUploadableFiles = [...newFiles]
            .map((file) => new UploadableFile(file))
            .filter((file) => !fileExists(file.id))
        files.value = files.value.concat(newUploadableFiles)
    }

    function fileExists(otherId: string) {
        return files.value.some(({ id }) => id === otherId)
    }

    function removeFile(file: UploadableFile) {
        const index = files.value.indexOf(file)

        if (index > -1) files.value.splice(index, 1)
    }

    return { files, addFiles, removeFile }
}