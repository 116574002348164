<template>
  <nav class="navbar is-black" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <router-link class="navbar-item" to="/">
        <div>
          <img src="../../public/logo_expanded.png" alt="coback logo">
        </div>
      </router-link>

      <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div id="navbar-body" class="navbar-menu">
      <div class="navbar-start">
        <router-link to="/home" class="navbar-item">Home</router-link>
        <a class="navbar-item">Tools (soon)</a>


        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link">Workspaces (soon)</a>
          <div class="navbar-dropdown">
            <a class="navbar-item">Workspace 1</a>
            <a class="navbar-item">Workspace 2</a>
            <a class="navbar-item">Workspace 3</a>
            <hr class="navbar-divider">
            <a class="navbar-item">
              Create a new workspace
            </a>
          </div>
        </div>
        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link">
            More (soon)
          </a>
          <div class="navbar-dropdown">
            <a class="navbar-item">
              About
            </a>
            <a class="navbar-item">
              Jobs
            </a>
            <a class="navbar-item">
              Contact
            </a>
            <hr class="navbar-divider">
            <a class="navbar-item">
              Report an issue
            </a>
            <router-link to="/demo" class="navbar-item">Demo</router-link>
          </div>
        </div>
      </div>

      <div class="navbar-end">
        <div class="navbar-item">
          <div class="buttons">
            <LogInOrOutButton/>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LogInOrOutButton from "@/components/LogInOrOutButton.vue";

export default defineComponent({
  components: { LogInOrOutButton },
  name: 'NavBar',
})
</script>

<style scoped lang="scss">

</style>