import { useSessionStorage } from "@vueuse/core"
import { defineStore } from 'pinia'
import auth0 from 'auth0-js'
import router from "@/router";
import axios from "axios";

const useAuth0Store = defineStore("auth0Store", {
    state: () => ({
        auth0: new auth0.WebAuth({
            domain: 'coback.eu.auth0.com',
            clientID: 'li2wOjbumlZJVMkDNQ1aOQVwTs3523OH',
            redirectUri: window.location.origin,
            audience: 'https://co-back.com/auth',
            responseType: 'token id_token',
            scope: 'openid'  // openid profile
        }),
        authResult: useSessionStorage("authResult", null, {
                // need to be explicitly declared. null is not an object and will imply a non-json serializer
                serializer: {
                    read: (v) => v ? JSON.parse(v) : null,
                    write: (v) => JSON.stringify(v),
                },
            },
        ),
    }),
    getters: {
        accessToken: (state) => { if (state.authResult) return state.authResult.accessToken },
        idToken: (state) => { if (state.authResult) return state.authResult.idToken },
        profile: (state) => { if (state.authResult) return state.authResult.idTokenPayload },
        // TODO: maybe make expiresAt more secure (what if it is invoked by a change that does not influence expiresIn)
        expiresAt: (state) => { if (state.authResult) return state.authResult.expiresIn * 1000 + new Date().getTime() },
    },
    actions: {
        isAuthenticated() {
            if(this.authResult)
            {
                return (new Date().getTime()) < this.expiresAt
            } else {
                return false
            }
        },
        login() { this.auth0.authorize() },
        handleAuthentication(){
            if (this.isAuthenticated()) {
                console.log("Authenticated")
                return;
            }
            console.log("Not authenticated")
            this.auth0.parseHash((err, current_auth_result) => {
                if (current_auth_result && current_auth_result.accessToken && current_auth_result.idToken) {
                    this.authResult = current_auth_result
                } else if (err) {
                    console.log(err)
                    alert(`Error: ${err.error}. Check the console for further details.`)
                }
                router.replace('/')
            })
        },
        logout() {
            this.authResult = null
            router.replace('/')
        },
        request_api(
            api_name,
            payload,
            request_function,
            configs = {}
        ){
            let is_dev = false
            try {
                is_dev = document.defaultView.__VUE_DEVTOOLS_GLOBAL_HOOK__.enabled === true
            } catch (e) {
                // console.log(e)
            }

            if (is_dev) { console.log("Development mode") }
            const axiosInstance = axios.create({
                baseURL: is_dev ? 'http://localhost:8000/api' : 'https://109-236-88-43.hosted-by-worldstream.net/api',
                headers: {
                    Authorization: `Bearer ${this.accessToken}`,
                    ...configs
                },
            });

            switch (request_function) {  // TODO: yes, this is very bad
                case axios.get:
                    return axiosInstance.get(`/${api_name}`, payload)
                case axios.post:
                    return axiosInstance.post(`/${api_name}`, payload)
                case axios.put:
                    return axiosInstance.put(`/${api_name}`, payload)
                case axios.delete:
                    return axiosInstance.delete(`/${api_name}`, payload)
                default:
                    throw `Unknown request request: ${request_function}`
            }
        },
        api_get(api_name, payload = {}, configs = {}) { return this.request_api(api_name, payload, axios.get, configs) },
        api_post(api_name, payload = {}, configs = {}) { return this.request_api(api_name, payload, axios.post, configs) },
        api_put(api_name, payload = {}, configs = {}) { return this.request_api(api_name, payload, axios.put, configs) },
        api_delete(api_name, payload = {}, configs = {}) { return this.request_api(api_name, payload, axios.delete, configs) },
    },
})

export default useAuth0Store;