import update_desktop from "@/scripts/update_desktop";
import traverse_file_tree from "@/scripts/filesystem_navigation"
import useAuthStore from "@/stores/authStore";

class Container {
    constructor(display_name, parent) {
        this.display_name = display_name;
        if(parent !== undefined) {
            parent.append(this);
        }
    }
}

class Directory extends Container {
    constructor(display_name, children, parent) {
        if(display_name === undefined)
            display_name = "__root";

        if(children === undefined)
            children = [];

        super(display_name, parent);
        this.children = new Set(children);  // container set
    }

    append(container) {
        console.log(`Appending ${container.display_name}`);

        container.display_name = this.get_safe_file_display_name(container.display_name)

        this.children.add(container);
    }

    contains_filename(file_display_name) {
        return [...this.children].map((child) => child.display_name).includes(file_display_name);
    }

    get_safe_file_display_name(file_display_name, iteration = undefined) {
        let new_filename = file_display_name
        if (iteration !== undefined) {
            new_filename = new_filename + iteration.toString()
        } else {
            iteration = 0
        }

        if (!this.contains_filename(new_filename)) {
            console.log(`New filename: ${new_filename}`)
            return new_filename;
        }

        console.log(`Filename taken: ${new_filename}`)
        return this.get_safe_file_display_name(file_display_name, iteration + 1);
    }
}

class File extends Container {
    constructor(display_name, concrete_file, parent) {
        super(display_name, parent);
        this.concrete_file = concrete_file;
    }

    append(container) {
        throw new Error(`Can't append ${container.display_name} to a file (${this.display_name})!`);
    }
}

function get_base64(file){
    return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onload = () => {resolve(fr.result)};
        fr.onerror = reject;
        fr.readAsDataURL(file);
    });
}

export default async function upload_files(data_transfer) {
    const items = data_transfer.items;

    // TODO: Yeah, I know it's trash. Rewrite
    function build_file_callback(register) {
        async function file_callback(file_entry, path) {
            await new Promise((resolve, reject) => {
                file_entry.file(async (file) => {
                    console.assert(register[file_entry.fullPath] === undefined, "Ambiguous filenames")
                    register[file_entry.fullPath] = await get_base64(file)
                    resolve()
                })
            })

            // return "debug"
        }

        return file_callback
    }

    function build_directory_callback(register) {
        function directory_callback(item, path) {
            console.assert(register[item.fullPath] === undefined, "Ambiguous directory names")
            register[item.fullPath] = "directory";
            // return "debug"
        }

        return directory_callback;
    }

    let register = {
        "elems": [],
        "parent": "__user_root"
    }

    for (const item of items) {
        let item_register = {}

        const concrete_item = item.webkitGetAsEntry();

        // let local_root_directory = new Directory()

        if (concrete_item) {
            await traverse_file_tree(
                build_file_callback(item_register),
                build_directory_callback(item_register),
                concrete_item,
            );
        } else {
            console.log("Undefined concrete item:");
            console.log(concrete_item);
        }

        register.elems.push(item_register);
    }

    const authStore = useAuthStore();
    console.log("Send")
    return authStore.api_post("upload", register)
}
