import useAuthStore from "@/stores/authStore";

export default function handle_error(error) {
    console.log(error)
    const error_handler = {
        401: {
            alert_msg: "Fehler bei Authorisation. Bitte neueinloggen.",
            callback: ()=>{
                const authStore = useAuthStore()
                authStore.logout()
            }
        },
        403: {
            alert_msg: "Aktion verboten, bitte support kontaktieren",
        },
    }[error.request.status] || {
        alert_msg: error.toString()
    }

    alert(error_handler.alert_msg)
    if (error_handler.callback !== undefined) error_handler.callback()
}
