<template>
    <NavBar/>
    <router-view/>
</template>

<script>
import {defineComponent} from 'vue';
import NavBar from "@/components/NavBar.vue";

import useAuthStore from "@/stores/authStore"
import useLoadingStore from "@/stores/loadingStore";

export default defineComponent({
    name: 'App',
    components: {NavBar},
  setup() {
    const authStore = useAuthStore()
    const loadingStore = useLoadingStore()
    authStore.handleAuthentication()
    return {}
  },
})
</script>

<style lang="scss">
@import '../public/styles.scss';
html,body{
  background-color:rgb(9, 10, 12);
}
.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>