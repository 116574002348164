<script setup lang="ts">

</script>

<template>
  <div class="columns pt-2 mt-2">
    <div class="column has-text-centered has-text-white">
      © 2024 COBACK
    </div>
    <div class="column has-text-centered">
      <router-link to="/guidelines/impressum">Impressum</router-link> | <router-link to="/guidelines/privacy">Datenschutz</router-link>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>