<template>
  <div id="desktop"></div>
</template>


<script>
import NjDesktop from '@/njdesktop_custom/src/njDesktop.js';
import '@/njdesktop_custom/src/assets/njDesktop.scss';

import { defineComponent } from "vue";
import make_drag_n_drop from "@/scripts/drag_n_drop"
import update_desktop from "@/scripts/update_desktop"
import useAuthStore from "@/stores/authStore";

export default defineComponent({
  name: 'HomeScreen',
  components: {},
  setup() {
    return {};
  },
  async mounted() {
    const authStore = useAuthStore()
    let backgroundImage = 'url("https://109-236-88-43.hosted-by-worldstream.net/static/desktop_wallpaper.jpg")'
    if (!authStore.isAuthenticated()) {
      authStore.login()
      // backgroundImage = ""  // TODO: change to a wallpaper that says to log in
    }

    const desktop = new NjDesktop(
        document.querySelector('#desktop'), {
          theme: 'cleanDark',
          dark: true,
          backgroundImage: backgroundImage,
        });

    desktop.icon_file = 'url(https://109-236-88-43.hosted-by-worldstream.net/static/icon_file.png)'
    desktop.icon_directory = "url('https://109-236-88-43.hosted-by-worldstream.net/static/icon_directory.png')"

    if (authStore.isAuthenticated()) {
      document.querySelector('#app').addEventListener("dragover", (e) => {
        // Prevent navigation.
        e.preventDefault();
      });

      make_drag_n_drop(document.querySelector('#desktop'), desktop)
      await update_desktop(desktop)
    }

    return { desktop }
  },
  emits: {},
  methods: {},
})


</script>

<style scoped lang="scss">
div {
  display: flex;
  height: 90vh;
  background-color: powderblue;
}

</style>