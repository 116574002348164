<template>
  <component :is="tag" class="file-preview">
    <div @mouseover="hovered = true" @mouseleave="hovered = false">
      <div class="metadata has-text-white unselectable" v-if="!hovered && file.metadata != null">
        {{ get_file_metadata }}
      </div>
    </div>
    <img :src="file.url" :alt="file.file.name" :title="file.file.name" width="200" height="300" class="unselectable"/>
    <span class="status-indicator loading-indicator unselectable" v-show="file.status == 'loading'">In Progress</span>
    <span class="status-indicator success-indicator unselectable" v-show="file.status == true">Uploaded</span>
    <span class="status-indicator failure-indicator unselectable" v-show="file.status == false">Error</span>
    <button @click="$emit('remove', file)" class="close-icon unselectable" aria-label="Remove">×</button>
  </component>
</template>

<script lang="ts">
import {computed, defineComponent, ref} from "vue"

export default defineComponent({
  name: 'FilePreview',
  components: {},
  props: {
    file: { type: Object, required: true },
    tag: { type: String, default: 'li' },
  },
  setup(props: any) {
    let hovered = ref(true)
    const get_file_metadata = computed(() => {
      const metadata = props.file.metadata

      return `
        Gas quantity detected:
        ${metadata["gas_sum"]} L
        \n\n
        Carbon emission:
        ${metadata["co2"]} kg
        `})

    return {
      hovered,
      get_file_metadata
    };
  },
  emits: {},
  methods: {},
})
</script>

<style scoped lang="scss">
.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

div {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.metadata {
  background-color: rgba(0,0,0,.8);
}

.file-preview {
  width: 20%;
  margin: 1rem 2.5%;
  position: relative;
  aspect-ratio: 1/1;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }

  .close-icon, .status-indicator {
    --size: 20px;
    position: absolute;
    line-height: var(--size);
    height: var(--size);
    border-radius: var(--size);
    box-shadow: 0 0 5px currentColor;
    right: 0.25rem;
    appearance: none;
    border: 0;
    padding: 0;
  }

  .close-icon {
    width: var(--size);
    font-size: var(--size);
    background: #933;
    color: #fff;
    top: 0.25rem;
    cursor: pointer;
  }

  .status-indicator {
    font-size: calc(0.75 * var(--size));
    bottom: 0.25rem;
    padding: 0 10px;
  }

  .loading-indicator {
    animation: pulse 1.5s linear 0s infinite;
    color: #000;
  }

  .success-indicator {
    background: #6c6;
    color: #040;
  }

  .failure-indicator {
    background: #933;
    color: #fff;
  }
}

@keyframes pulse {
  0% {
    background: #fff;
  }

  50% {
    background: #ddd;
  }

  100% {
    background: #fff;
  }
}
</style>