<template>
  <div id="root">
    <DropZone class="drop-area" @files-dropped="addFiles" #default="{ dropZoneActive }">
      <label for="file-input">
    <span v-if="dropZoneActive">
        <span>Drop Them Here</span>
        <span class="smaller">to add them</span>
    </span>
        <span v-else>
        <span>Drag Your Files Here</span>
        <span class="smaller">
            or <strong><em>click here</em></strong> to select files
        </span>
    </span>
        <input type="file" id="file-input" inert multiple @change="onInputChange"/>
      </label>
      <ul class="image-list" v-show="files.length">
        <FilePreview v-for="file of files" :key="file.id" :file="file" tag="li" @remove="removeFile"/>
      </ul>
    </DropZone>
    <button @click.prevent="tryUploadFiles(files)" class="upload-button">
      {{ authStore.isAuthenticated() ? "Upload" : "Login to upload" }}
    </button>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { uploadFiles }  from '@/scripts/file-upload.js'
import DropZone from '@/components/DropZone.vue'
import FilePreview from '@/components/FilePreview.vue'
import useFileList from '@/scripts/file-list'
import useAuthStore from "@/stores/authStore";

export default defineComponent({
  name: 'HomeScreen',
  components: { DropZone, FilePreview },
  setup() {
    const { files, addFiles, removeFile } = useFileList()
    const authStore = useAuthStore()

    function onInputChange(e) {
      if (e.target instanceof HTMLInputElement) {
        if (null !== e.target.files) {
          addFiles(e.target.files)
        }
      }
    }

    function tryUploadFiles(files) {
      if (!authStore.isAuthenticated()) {
        return null
      }
      return uploadFiles(files)
    }

    return {
      files,
      addFiles,
      removeFile,
      onInputChange,
      tryUploadFiles,
      authStore,
    }
  }
});
</script>

<style scoped lang="scss">
@use '@/../public/styles.scss' as styles;
.drop-area {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 50px;
  background: styles.$primary;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transition: .2s ease;

  &[data-active=true] {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
}

.image-list {
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  padding: 0;
}

.upload-button {
  display: block;
  appearance: none;
  border: 0;
  border-radius: 50px;
  padding: 0.75rem 3rem;
  margin: 1rem auto;
  font-size: 1.25rem;
  font-weight: bold;
  background: styles.$primary;
  color: #fff;
  text-transform: uppercase;
}
label {
  font-size: 36px;
  cursor: pointer;
  display: block;

  span {
    display: block;
  }

  input[type=file]:not(:focus-visible) {
    // Visually Hidden Styles taken from Bootstrap 5
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
  }

  .smaller {
    font-size: 16px;
  }
}
#root {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: styles.$primary-invert;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>