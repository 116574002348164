import update_desktop from "@/scripts/update_desktop";
import upload_files from "@/scripts/containers";
import useAuthStore from "@/stores/authStore";
import useLoadingStore from "@/stores/loadingStore";
import handle_error from "@/scripts/handle_error";

export default function make_drag_n_drop(element, desktop) {
    element.addEventListener("drop", async function(event) {
        event.preventDefault();

        const loadingStore = useLoadingStore()
        try {
            loadingStore.push()
            await upload_files(event.dataTransfer)
        } catch (error) {
            handle_error(error)
        } finally {
            loadingStore.pop()
        }

        // if (element.id === "desktop")
        update_desktop(desktop)
    }, false);
}
