import ToolBar from "../src/njToolBar";
import NjIconList, {NjIconlistOrientation, NjIconlistView} from "../src/controls/iconlist/njIconList";
import {NJ_TOOLBUTTON_ICON} from "../src/njToolButtonTypes";
import { dataSvg, lgIcon, mdIcon, smIcon, tileIcon, xlIcon} from "../demo/assets/iconRepository";
import { get_files } from "../../scripts/get_files";

export const DirectoryView = class {
    buildIcons(substructure) {
        this.iconlist.clear()
        for (const elem of substructure) {
            const icon = {
                "file": this.desktop.icon_file,
                "directory": this.desktop.icon_directory
            }[elem["type"]]
            this.iconlist.addIcon({
                icon: icon,
                title: elem["name"],
                tileDetailKey: 'fileType',
                /*
                metadata: {
                    fileType: 'Application',
                    size: Math.round(Math.random() * 1000000),
                    date: new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime())),
                },*/
                dblclick: async () => {
                    if (elem["type"] === "directory") {
                        new DirectoryView(this.desktop, elem["name"], elem["elems"],`${this.path}/${this.window.title}`);
                    } else if (elem["type"] === "file") {
                        // TODO: this works, but I need to send file over (img.src = the file, whatever)
                        /*
                        const img = document.createElement('img');
                        img.src = 'https://media.geeksforgeeks.org/wp-content/uploads/20190529122828/bs21.png';
                        img.classList.add("unselectable");

                        const w = this.desktop.createDialog({
                            title: elem["name"],
                            width: img.width,
                            height: img.height + 100,
                        });
                        w.setContentElement(img);
                        */
                    }
                }
            });
        }
    }

    constructor(desktop, display_name, substructure, path) {
        this.desktop = desktop
        this.path = path
        this.window = this.desktop.createWindow(display_name)
        this.window.setWidth(800);
        this.window.setHeight(400);
        this.toolbar = new ToolBar();
        this.iconlist = new NjIconList(null, {
            orientation: NjIconlistOrientation.VERTICAL,
            view: NjIconlistView.M,
        });

        this.toolButtonXL = this.toolbar.addToolButton({
            title: "Extra large icons",
            type: NJ_TOOLBUTTON_ICON,
            icon: dataSvg(xlIcon(this.desktop.dark)),
            click: () => {
                this.iconlist.setView(NjIconlistView.XL);
            }
        });

        this.toolButtonL = this.toolbar.addToolButton({
            title: "Large icons",
            type: NJ_TOOLBUTTON_ICON,
            icon: dataSvg(lgIcon(this.desktop.dark)),
            click: () => {
                this.iconlist.setView(NjIconlistView.L);
            }
        });

        this.toolButtonM = this.toolbar.addToolButton({
            title: "Medium icons",
            type: NJ_TOOLBUTTON_ICON,
            icon: dataSvg(mdIcon(this.desktop.dark)),
            click: () => {
                this.iconlist.setView(NjIconlistView.M);
            }
        });

        this.toolButtonS = this.toolbar.addToolButton({
            title: "Small icons",
            type: NJ_TOOLBUTTON_ICON,
            icon: dataSvg(smIcon(this.desktop.dark)),
            click: () => {
                this.iconlist.setView(NjIconlistView.S);
            }
        });

        this.toolButtonTile = this.toolbar.addToolButton({
            title: "Tile",
            type: NJ_TOOLBUTTON_ICON,
            icon: dataSvg(tileIcon(this.desktop.dark)),
            click: () => {
                this.iconlist.setView(NjIconlistView.TILE);
            }
        });

        this.window.addToolbar(this.toolbar);
        this.iconlist.setView(NjIconlistView.TILE)
        this.buildIcons(substructure)

        this.window.setContentObject(this.iconlist);
    }

    themeChange(sender, {dark}){
        this.toolButtonXL.setIcon(dataSvg(xlIcon(dark)));
        this.toolButtonL.setIcon(dataSvg(lgIcon(dark)));
        this.toolButtonM.setIcon(dataSvg(mdIcon(dark)));
        this.toolButtonS.setIcon(dataSvg(smIcon(dark)));
        this.toolButtonTile.setIcon(dataSvg(tileIcon(dark)));
    }
}

export const DirectoryIcon = class {
    constructor(desktop, display_name, substructure, icon, is_file) {
        desktop.getIconList().addIcon({
            icon: icon,
            title: display_name,
            dblclick: () => {
                if (!is_file) {
                    new DirectoryView(desktop, display_name, substructure, "");
                }
            }
        });
    }
}
